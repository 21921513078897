<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS USUARIOS:
                        </span>
                        <hr>
                        <!-- <b-row class="justify-content-center text-center"> -->
                        <b-row class="justify-content-center text-center">
                            <b-col cols="6" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-hiking fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listaGuias.length}}</span>
                                <br>
                                <span class="text-muted">Guías registrados</span>
                            </b-col>
                            <b-col cols="6" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-hiking fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listAdministrador.length}}</span>
                                <br>
                                <span class="text-muted">Administradores registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
                
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-cog"></i><span class="h5"> Gestión de Usuarios</span>
                    </CCardHeader>
                    <CCardBody>
                        <b-row class="justify-content-center text-center">
                            <!-- <b-col cols="6" class="my-2">
                                <b-button block variant="custom" :to="{name:'Proyectos'}">
                                    <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                                </b-button>
                            </b-col> -->
                            <b-col cols="6" class="my-2">
                                <b-button block variant="custom" @click="modalRegistrarGuia = true">
                                    <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro</span>
                                </b-button>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Listado de Guías registrados </span>
                        <b-button v-if="listaGuias.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel(1)">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model.trim="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listaGuias" :fields="camposLote" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nrodoc)="data">
                                        {{data.item.usernrodoc}}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombre}}
                                    </template>
                                    <template v-slot:cell(apellidoPaterno)="data">
                                        {{data.item.apellidoPaterno}}
                                    </template>
                                    <template v-slot:cell(apellidoMaterno)="data">
                                        {{data.item.apellidoMaterno}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarGuia(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarGuia(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>

            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Listado de Administradores registrados </span>
                        <b-button v-if="listAdministrador.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel(2)">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPaginaAd" :options="paginaOpcionesAd"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model.trim="filterAd" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listAdministrador" :fields="camposLote" :current-page="paginaActualAd" :per-page="porPaginaAd" :filter="filterAd" :filter-included-fields="filterOnAd" @filtered="onFilteredAd" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nrodoc)="data">
                                        {{data.item.usernrodoc}}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombre | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(apellidoPaterno)="data">
                                        {{data.item.apellidoPaterno | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(apellidoMaterno)="data">
                                        {{data.item.apellidoMaterno | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarGuia(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarGuia(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActualAd" :total-rows="filasTotalesAd" :per-page="porPaginaAd" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalRegistrarGuia" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Nuevo Usuario</span>
                </h6>
                <CButtonClose @click="modalRegistrarGuia = false" class="text-white" />
            </template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarGuias)">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el nombres" v-model.trim="datosGuia.nombre"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Apellido paterno" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido paterno::" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el apellido paterno" v-model.trim="datosGuia.apellidoPaterno"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Apellido materno" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido materno:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el apellido materno" v-model.trim="datosGuia.apellidoMaterno"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- <b-col md="6">
                            <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de documento:" class="mb-2">
                                    <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurTipoDocumento">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col> -->
                        <b-col md="6">
                            <validation-provider name="tipo de persona" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Persona:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listTipoPersona => listTipoPersona.idpersona" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoPersona , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoPersona" :options="listTipoPersona" @search:blur="blurTipoPersona">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de documento:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos" @search:blur="blurTipoDocumentos">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="6">
                            <validation-provider name="Número de documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Numero de Documento" v-model.trim="datosGuia.numeroDocumento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Teléfono" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Teléfono:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el telefono" v-model.trim="datosGuia.telefono"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de nacimiento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de nacimiento:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosGuia.fechaNacimiento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="correo" :rules="{required: false, email:true}" v-slot="validationContext">
                                <b-form-group label="Correo:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model.trim="datosGuia.email"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Guardar
                            </b-button>
                            <b-button variant="danger" @click="modalRegistrarGuia = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #footer>
                <span></span>
            </template>
        </CModal>

        <CModal :show.sync="modalActualizarGuia" size="lg" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                    <span class="h5"> Actualizar Usuario</span></h6>
                <CButtonClose @click="modalActualizarGuia = false" class="text-white" />
            </template>
            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(actualizarGuia)">
                    <b-row>
                        <b-col md="12">
                            <span class ="h6 text-muted">Datos Personales:</span>
                        </b-col>
                        <hr>
                        <b-col md="6">
                            <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Nombre:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el nombres" v-model.trim="datosActualizarGuia.nombre"></b-form-input>                                    
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Apellido paterno" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido paterno::" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el apellido paterno" v-model.trim="datosActualizarGuia.apellidoPaterno"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Apellido materno" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Apellido materno:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el apellido materno" v-model.trim="datosActualizarGuia.apellidoMaterno"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo de persona" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de Persona:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listTipoPersona => listTipoPersona.idpersona" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarGuia.idTipoPersona , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarGuia.idTipoPersona" :options="listTipoPersona" @search:blur="blurActualizarTipoPersona">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                                <b-form-group label="Tipo de documento:" class="mb-2">
                                    <!-- <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosGuia.idTipoDocumento" :options="listDocumentos"> -->
                                    <v-select :reduce="listDocumentos => listDocumentos.idDocumento" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarGuia.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarGuia.idTipoDocumento" :options="listDocumentos" @search:blur="blurActualizarTipoDocumentos">
                                        <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                    </v-select>
                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Teléfono" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Teléfono:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el telefono" v-model.trim="datosActualizarGuia.telefono"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Correo" :rules="{required: false, email:true}" v-slot="validationContext">
                                <b-form-group label="Correo:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model.trim="datosActualizarGuia.email"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="fecha de nacimiento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha de nacimiento:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActualizarGuia.fechaNacimiento"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <hr>
                        <b-col md="12">
                            <span class ="h6 text-muted">Usuarios:</span>
                        </b-col>
                        <hr>
                        <b-col md="6">
                            <validation-provider name="Número de documento" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Número de documento:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese el Numero de Documento" v-model.trim="datosActualizarGuia.usernrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="Contraseña" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Contraseña:" class="mb-2">
                                    <b-form-input  :state="getValidationState(validationContext)" placeholder="Ingrese su Contraseña" v-model.trim="datosActualizarGuia.passnrodoc"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col lg="12" class="text-right my-2">
                            <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Modificar
                            </b-button>
                            <b-button variant="danger" @click="modalActualizarGuia = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>

<script>
import firebase from 'firebase';
// import TipoDocumentos from "@/helpers/TipoDocumentos";
const dbUsuario = firebase.firestore().collection('users');
const dbDocumento = firebase.firestore().collection('tipoDocumento');
const dbTipoPersona = firebase.firestore().collection('tipoPersona');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            disabled: false,
            listaGuias: [],
            listAdministrador: [],
            listDocumentos: [],
            listTipoPersona: [],
            camposLote: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nrodoc",
                    label: "Nro Doc.",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "apellidoPaterno",
                    label: "Apellido paterno",
                    class: "text-center",
                },
                {
                    key: "apellidoMaterno",
                    label: "Apellido materno",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            filasTotalesAd: 1,
            paginaActual: 1,
            paginaActualAd:1,
            porPagina: 10,
            porPaginaAd: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            paginaOpcionesAd: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            filterAd: null,
            filterOnAd: [],

            

            datosGuia: {
                idCliente: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoPersona: null,
                idTipoDocumento: null,
                numeroDocumento: '',
                telefono: '',
                email: '',
                // fechaNacimiento: moment().format('YYYY-MM-DD'),
                fechaNacimiento: moment().format('YYYY-MM-DD'),
            },

            datosActualizarGuia: {
                idGuia: '',
                idCliente: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoPersona: null,
                idTipoDocumento: null,
                usernrodoc: '',
                passnrodoc: '',
                telefono: '',
                email: '',
                fechaNacimiento: ''
            },

            comboTipoDocumentos: [],

            modalRegistrarGuia: false,
            modalActualizarGuia: false,
        }
    },
    methods: {
        exportDataToExcel(valor) {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel;
            
            if(valor == 1){
                dataExcel = this.listaGuias.map(data => {
                    return {
                        A: valUpper(data['nombre']),
                        B: valUpper(data['apellidoPaterno']),
                        C: valUpper(data['apellidoMaterno']),
                        D: valUpper(this.listTipoPersona && this.listTipoPersona !== undefined ?
                            this.listTipoPersona.filter(x => x.idpersona == data.rol).length > 0 ? 
                            this.listTipoPersona.filter(x => x.idpersona == data.rol)[0].descripcion : '' : ''),
                        E: valUpper(this.listDocumentos && this.listDocumentos !== undefined ?
                            this.listDocumentos.filter(x => x.idDocumento == data.idTipoDocumento).length > 0 ? 
                            this.listDocumentos.filter(x => x.idDocumento == data.idTipoDocumento)[0].nombre : '' : ''),
                        F: valUpper(data['usernrodoc']),
                        G: valUpper(data['passnrodoc']),
                        H: valUpper(data['telefono']),
                        I: valDate(data['fechaNacimiento']),
                        J: valUpper(data['email']),
                    };
                });
            }else{
                dataExcel = this.listAdministrador.map(data => {
                    return {
                        A: valUpper(data['nombre']),
                        B: valUpper(data['apellidoPaterno']),
                        C: valUpper(data['apellidoMaterno']),
                        D: valUpper(this.listTipoPersona && this.listTipoPersona !== undefined ?
                            this.listTipoPersona.filter(x => x.idpersona == data.rol).length > 0 ? 
                            this.listTipoPersona.filter(x => x.idpersona == data.rol)[0].descripcion : '' : ''),
                        E: valUpper(this.listDocumentos && this.listDocumentos !== undefined ?
                            this.listDocumentos.filter(x => x.idDocumento == data.idTipoDocumento).length > 0 ? 
                            this.listDocumentos.filter(x => x.idDocumento == data.idTipoDocumento)[0].nombre : '' : ''),
                        F: valUpper(data['usernrodoc']),
                        G: valUpper(data['passnrodoc']),
                        H: valUpper(data['telefono']),
                        I: valDate(data['fechaNacimiento']),
                        J: valUpper(data['email']),
                    };
                });
            }

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 30, //A
                },
                {
                    wch: 30, //B
                },
                {
                    wch: 30, //C
                },
                {
                    wch: 40, //D
                },
                {
                    wch: 40, //E
                },
                {
                    wch: 20, //F
                },
                {
                    wch: 20, //G
                },
                {
                    wch: 15, //H
                },
                {
                    wch: 15, //I
                },
                {
                    wch: 30, //CJ
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: valor == 1 ? 'GUÍAS' : 'ADMINISTRADORES',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NOMBRE',
                },
                {
                    f: 'B4',
                    v: 'APELLIDO PATERNO',
                },
                {
                    f: 'C4',
                    v: 'APELLIDO MATERNO',
                },
                {
                    f: 'D4',
                    v: 'TIPO DE PERSONAL',
                },
                {
                    f: 'E4',
                    v: 'TIPO DE DOCUMENTO',
                },
                {
                    f: 'F4',
                    v: 'NRO. DE DOCUMENTO',
                },
                {
                    f: 'G4',
                    v: 'CONTRASEÑA',
                },
                {
                    f: 'H4',
                    v: 'TELEFONO',
                },
                {
                    f: 'I4',
                    v: 'FECHA NACIMIENTO',
                },
                {
                    f: 'J4',
                    v: 'EMAIL',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, valor == 1 ? 'GUIAS' : 'ADMINISTRADORES');
            XLSX.writeFile(wb, `${valor == 1 ?  'GUIAS' : 'ADMINISTRADORES'} ${now}.xlsx`);
            
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        onFilteredAd(itemsFiltrados) {
            this.filasTotalesAd = itemsFiltrados.length
            this.paginaActualAd = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        // obtenerTipoDocumento() {
        //     let me = this;
        //     me.comboTipoDocumentos = TipoDocumentos;
        // },
        blurTipoDocumentos() {
            this.computedForms.refs['tipo de documento'].validate();
        },
        blurTipoPersona() {
            // this.computedFormsP.refs['tipo de persona'].validate();
            this.computedForms.refs['tipo de persona'].validate();
        },
        blurActualizarTipoDocumentos() {
            this.computedFormActualizar.refs['tipo de documento'].validate();
        },
        blurActualizarTipoPersona() {
            // this.computedFormsP.refs['tipo de persona'].validate();
            this.computedFormActualizar.refs['tipo de persona'].validate();
        },
        listarGuias() {
            let me = this;
            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.datosGuia.idCliente))
            .where("rol","==","guia")
            .onSnapshot((querySnapshot) => {
                me.listaGuias = [];
                querySnapshot.forEach((doc) => {
                    me.listaGuias.push({
                        idGuias: doc.id,
                        nombre: doc.data().nombre,
                        apellidoPaterno: doc.data().apellidoPaterno,
                        apellidoMaterno: doc.data().apellidoMaterno,
                        idTipoDocumento: doc.data().idTipoDocumento,
                        usernrodoc: doc.data().usernrodoc,
                        passnrodoc: doc.data().passnrodoc,
                        telefono: doc.data().telefono,
                        email: doc.data().email,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaModificacion: doc.data().fechaModificacion,
                        idCliente: doc.data().idCliente,
                        rol: doc.data().rol,
                        fechaNacimiento: doc.data().fechaNacimiento,
                    });
                
                });
                me.listaGuias.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });

        },
        listarAdmnistrador() {
            let me = this;
            dbUsuario
            .where("estado" , "==" , 2)
            .where("idCliente", "==", String(me.datosGuia.idCliente))
            .where("rol","==","administrador")
            .onSnapshot((querySnapshot) => {
                me.listAdministrador = [];
                querySnapshot.forEach((doc) => {
                    me.listAdministrador.push({
                        idGuias: doc.id,
                        nombre: doc.data().nombre,
                        apellidoPaterno: doc.data().apellidoPaterno,
                        apellidoMaterno: doc.data().apellidoMaterno,
                        idTipoDocumento: doc.data().idTipoDocumento,
                        usernrodoc: doc.data().usernrodoc,
                        passnrodoc: doc.data().passnrodoc,
                        telefono: doc.data().telefono,
                        email: doc.data().email,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaModificacion: doc.data().fechaModificacion,
                        idCliente: doc.data().idCliente,
                        rol: doc.data().rol,
                        fechaNacimiento: doc.data().fechaNacimiento,
                    });
                
                });
                me.listAdministrador.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });

        },
        eliminarGuia(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el guía?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbUsuario.doc(param.item.idGuias).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                        me.disabled = false;
                    })
                    .catch((error) => {
                        me.disabled = false;
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            })
        },
        
        listarDocumentos() {
            let me = this;
            dbDocumento
            .where("estado" , "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listDocumentos  = [];
                querySnapshot.forEach((doc) => {
                    me.listDocumentos.push({
                        idDocumento: doc.id,
                        nombre: doc.data().nombre,
                    });
                });
                
                // me.listDocumentos.sort((a,b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });

            dbTipoPersona
            .where("estado", "==" , 2)
            .onSnapshot((querySnapshot) => {
                me.listTipoPersona = [];
                querySnapshot.forEach((doc) => {
                    me.listTipoPersona.push({
                        idpersona: doc.data().idPersona,
                        descripcion: doc.data().descripcion,
                    });
                });
                
            });
        },
        registrarGuias() {
            let me = this;
            me.disabled = true;
            dbUsuario.add({
                nombre: me.datosGuia.nombre.trim(),
                apellidoPaterno: me.datosGuia.apellidoPaterno.trim(),
                apellidoMaterno: me.datosGuia.apellidoMaterno.trim(),
                idTipoDocumento: me.datosGuia.idTipoDocumento.trim(),
                usernrodoc: me.datosGuia.numeroDocumento.trim(),
                passnrodoc: me.datosGuia.numeroDocumento.trim(),
                telefono: me.datosGuia.telefono.trim(),
                email: me.datosGuia.email.trim(),
                fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                fechaEliminar: '',
                fechaModificacion: '',
                idCliente: me.datosGuia.idCliente.trim(),
                rol: me.datosGuia.idTipoPersona.trim(),
                estado: 2,
                fechaNacimiento: me.datosGuia.fechaNacimiento,
            }).then((docRef) => {
                me.swat('success', 'Registrado satisfactoriamente');
                me.modalRegistrarGuia = false;
                me.disabled = false;
            })
            .catch((error) => {
                me.disabled = false;
                me.swat('error', 'Algo salió mal!');
            });
        },
        actualizarGuia() {
            let me = this;
            me.disabled = true;
            dbUsuario.doc(me.datosActualizarGuia.idGuia).update({
                    nombre: me.datosActualizarGuia.nombre.trim(),
                    apellidoPaterno: me.datosActualizarGuia.apellidoPaterno.trim(),
                    apellidoMaterno: me.datosActualizarGuia.apellidoMaterno.trim(),
                    rol: me.datosActualizarGuia.idTipoPersona.trim(),
                    idTipoDocumento: me.datosActualizarGuia.idTipoDocumento.trim(),
                    usernrodoc: me.datosActualizarGuia.usernrodoc.trim(),
                    passnrodoc: me.datosActualizarGuia.passnrodoc.trim(),
                    telefono: me.datosActualizarGuia.telefono.trim(),
                    email: me.datosActualizarGuia.email.trim(),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaNacimiento: me.datosActualizarGuia.fechaNacimiento.trim(),
                })
                .then(() => {
                    //console.log("Actualizado correctamente");
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarGuia = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        abrirModalActualizarGuia(param) {
            this.datosActualizarGuia.idGuia = param.item.idGuias.trim();
            this.datosActualizarGuia.idCliente = param.item.idCliente.trim();
            this.datosActualizarGuia.nombre = param.item.nombre.trim();
            this.datosActualizarGuia.apellidoPaterno = param.item.apellidoPaterno.trim();
            this.datosActualizarGuia.apellidoMaterno = param.item.apellidoMaterno.trim();
            this.datosActualizarGuia.idTipoPersona = param.item.rol.trim();
            this.datosActualizarGuia.idTipoDocumento = param.item.idTipoDocumento.trim();
            this.datosActualizarGuia.usernrodoc = param.item.usernrodoc.trim();
            this.datosActualizarGuia.passnrodoc = param.item.passnrodoc.trim();
            this.datosActualizarGuia.telefono = param.item.telefono.trim();
            this.datosActualizarGuia.email = param.item.email.trim();
            this.datosActualizarGuia.fechaNacimiento = param.item.fechaNacimiento;
            this.modalActualizarGuia = true;
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

        resetModalRegistrarGuia() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosGuia.nombre = '';
            this.datosGuia.apellidoPaterno = '';
            this.datosGuia.apellidoMaterno = '';
            this.datosGuia.idTipoDocumento = null;
            this.datosGuia.idTipoPersona = null;
            this.datosGuia.numeroDocumento = '';
            this.datosGuia.telefono = '';
            this.datosGuia.email = '';
            // this.datosGuia.fechaNacimiento = moment().format('YYYY-MM-DD');
            this.datosGuia.fechaNacimiento = moment().format('YYYY-MM-DD');
        },
        resetModalActualizarGuia() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosActualizarGuia.idCliente = '';
            this.datosActualizarGuia.nombre = '';
            this.datosActualizarGuia.apellidoPaterno = '';
            this.datosActualizarGuia.apellidoMaterno = '';
            this.datosActualizarGuia.idTipoDocumento = null;
            this.datosActualizarGuia.idTipoPersona = null;
            this.datosActualizarGuia.usernrodoc = '';
            this.datosActualizarGuia.passnrodoc = '';
            this.datosActualizarGuia.telefono = '';
            this.datosActualizarGuia.email = '';
            this.datosActualizarGuia.fechaNacimiento = '';
        },

    },
    computed: {
        computedForms() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalRegistrarGuia: function (val) {
            if (val == false) {
                this.resetModalRegistrarGuia();
            }
        },
        modalActualizarGuia: function (val) {
            if (val == false) {
                this.resetModalActualizarGuia();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            // this.obtenerTipoDocumento();
            let user = this.$store.state.user;
            this.datosGuia.idCliente = user.idCliente;
            this.listarDocumentos();
            this.listarGuias();
            this.listarAdmnistrador();
            // console.log(this.$store.state.user);
        }

    },
}
</script>